.App {
  text-align: center;
}

.body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  align-items: center;
  font-size: calc(10px + 1.5vmin);
  justify-content: center;
  color: white;
}

.stat-container {
  padding: 2em;
  background-image: linear-gradient(#373c48, #3c424d);
  border-radius: 0;
  margin: 5vw;
  height: fit-content;
  min-height: 25.7%;
}

.min-width {
  min-width: 55%;
}


.left-div {
  display: flex;
  flex-direction: column;
}

.last-day-container {
  margin-top: auto;
  margin-bottom: 30%;
}

.query-container {
  display: flex;
}

.total-num-searches {
  margin-top: 0.5em;
}

.small {
  font-size: 40px;
}

.num-query:first-of-type {
  margin-top: 2em;
}

.num-query {
  margin: 1.5em;
  text-align: left;
}

.container {
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1em;
}

.val-total-num-searches {
  font-size: 3em;
}

.progress-circle {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

.numbers {
  margin-left: auto;
}

@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
  }

  .stat-container {
    margin: 3em auto 3em auto;
    width: 60%

  }

  .small {
    font-size: 35px;
  }

  .val-total-num-searches {
    margin-top: 0;
    font-size: 3.5em;
  }

  h2 {
    font-size: 0.8em;
  }

}



